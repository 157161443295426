import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getClients = createAsyncThunk('oauth/getClients', async (params) => fetchRequest('/services/oauth2/client', 'GET', params));

export const getClient = createAsyncThunk('oauth/getClient', async (id) => fetchRequest(`/services/oauth2/client/${id}`, 'GET', {id}));

export const updateClient = createAsyncThunk(
    'oauth/updateClient',
    async (body, thunkAPI) => {
        const {id, ...data} = body;
        await fetchRequest(`/services/oauth2/client/${id}`, 'PUT', null, data);
        thunkAPI.dispatch(getClients());
    }
);

export const createClient = createAsyncThunk(
    'oauth/createClient',
    async (body, thunkAPI) => {
        const payload = await fetchRequest('/services/oauth2/client', 'POST', null);
        // This is temporary as we shouldn't be updating when creating
        thunkAPI.dispatch(updateClient({
            id: payload.id,
            scope: body.scope,
            isActive: true,
            description: body.description
        }));
    }
);

export const deleteClient = createAsyncThunk(
    'oauth/deleteClient',
    async (id, thunkAPI) => {
        await fetchRequest(`/services/oauth2/client/${id}`, 'DELETE', {id});
        thunkAPI.dispatch(getClients());
    }
);
