import {createAsyncThunk} from '@reduxjs/toolkit';
import moment from 'moment';
import omitEmpty from '../utils/omitEmpty';
import fetchRequest from './utils';
import * as demoResponses from './demoResponses/appAwareDashboard';

export const getEndpoints = createAsyncThunk('appAware/getEndpoints', async (params) => fetchRequest('/threats/endpoints', 'GET', params));

export const requestEndpoint = createAsyncThunk('appAware/requestEndpoint', async (companyId) => fetchRequest('/threats/endpoints', 'POST', {companyId}));

export const getOverview = createAsyncThunk('appAware/getOverview', async (dateFrom) => fetchRequest(`/threats/overview?dateFrom=${encodeURIComponent(dateFrom)}`, 'GET', {dateFrom}));

export const getReport = createAsyncThunk('appAware/getReport', async (dateFrom) => fetchRequest(`/threats/report?dateFrom=${encodeURIComponent(dateFrom)}`, 'GET', {dateFrom}));

export const getDefinitions = createAsyncThunk('appAware/getDefinitions', async ({companyId}) => fetchRequest('/threats/definitions', 'GET', omitEmpty({companyId})));

export const getNotificationConfiguration = createAsyncThunk('appAware/getNotificationConfiguration', async ({applicationId, companyId}) => fetchRequest('/threat-analytics/applicationProperties', 'GET', omitEmpty({label: 'notifications', applicationId, companyId})));

export const getEvents = createAsyncThunk('appAware/getEvents', async (params) => fetchRequest('/threats/events', 'GET', omitEmpty({
    companyId: params.companyId,
    includeHidden: params.includeHidden,
    start: params.start
})));

export const getUai = createAsyncThunk('appAware/getUai', async (params) => fetchRequest('/threats/uai', 'GET', omitEmpty({
    range: params.range,
    start: params.start,
    companyId: params.companyId
})));

export const getGuardActivityTotal = createAsyncThunk('appAware/getGuardActivityTotal', async (params) => fetchRequest('/threats/activity', 'GET', omitEmpty({
    range: params.range,
    start: params.start,
    companyId: params.companyId
})));

export const getGuardsFired = createAsyncThunk('appAware/getGuardsFired', async (params) => fetchRequest('/threats/fired', 'GET', omitEmpty({
    range: params.range,
    start: params.start,
    companyId: params.companyId
})));

export const getDashboardData = createAsyncThunk('appAware/getDashboardData', async (params) => {
    const query = omitEmpty({
        companyId: params.companyId,
        start: params.start
    });
    return fetchRequest('/threats/health', 'POST', query);
});

export const updateThreatLogItem = createAsyncThunk('appAware/updateThreatLogItem', async (params) => {
    const {
        uai,
        hidden,
        companyId,
        druid
    } = params;
    return fetchRequest('/threats/threatLogItem', 'POST', {companyId}, {uai, hidden, druid});
});

export const getThreatLog = createAsyncThunk('appAware/getThreatLog', async (params) => {
    const query = omitEmpty({
        companyId: params.companyId,
        limit: params.limit,
        page: params.page,
        orderBy: params.orderBy,
        order: params.order,
        includeHidden: params.includeHidden,
        start: params.start
    });
    return fetchRequest('/threats/threatLog', 'POST', query, {filter: params.filter});
});

export const getTable = createAsyncThunk('appAware/getTable', async (params) => {
    const query = omitEmpty({
        companyId: params.companyId,
        limit: params.limit,
        page: params.page,
        queryExecutionId: params.queryExecutionId,
        nextToken: params.nextToken
    });
    return fetchRequest('/threats/table', 'POST', query, {filter: params.filter});
});

export const getThreatLogDetails = createAsyncThunk('appAware/getThreatLogDetails', async (params) => {
    const query = omitEmpty({
        companyId: params.companyId,
        uai: params.uai,
        start: moment.utc(params.start).valueOf()
    });
    return fetchRequest('/threats/threatLogItem', 'GET', query);
});

export const updateNotificationConfiguration = createAsyncThunk('appAware/updateNotificationConfiguration', async (data) => {
    const {
        companyId,
        applicationId,
        ...other
    } = data;
    return fetchRequest('/threat-analytics/applicationProperties', 'POST', omitEmpty({applicationId, companyId, label: 'notifications'}), other);
});

// mock thunk that return a response with demo data
export const getMockDashboardData = createAsyncThunk('appAware/getDashboardData', async (responseName) => demoResponses[responseName]);
