import {
    ADP_MOCK_DATA_ENABLED,
    ADP_API_URL,
    ADP_FRONTEND_URL,
    ADP_VANITY_DOMAIN,
    GOOGLE_ANALYTICS_ENABLED,
    GA_TRACKING_ID,
    HIDDEN_ROUTES,
    K6I_SIGNIN_PAGE_URL,
    APAAS_DISABLED
} from './environment';

export const ROLES = {
    admin: 'arxan-admin',
    accountOwner: 'account-owner',
    user: 'user',
    securityResearcher: 'security-researcher'
};

/**
 * Defines available application routes, their titles and role access.
 * This is used to make sure that all routes are
 * consistant across all components and pages.
 * @type {Object}
 */
export const ROUTES = {
    productDownload: {
        route: '/protect/on-premise/product-download',
        title: 'Product download',
        access: ['user', 'account-owner'],
        crumbs: ['Protect']
    },
    manageLicenses: {
        route: '/manage/system/licenses',
        title: 'Licenses',
        access: ['user', 'account-owner', 'arxan-admin'],
        crumbs: ['Manage']
    },
    manageLicensesById: {
        route: '/manage/system/licenses/:licenseId',
        title: 'Licenses',
        access: ['user', 'account-owner', 'arxan-admin'],
        crumbs: ['Manage'],
        regex: /^\/manage\/system\/licenses\/[^/]+$/
    },
    manageLicenseTokens: {
        route: '/manage/setup/license-tokens',
        title: 'License tokens',
        access: ['user', 'account-owner'],
        crumbs: ['Manage']
    },
    protectionHistory: {
        route: '/analyze/logs/protection-history',
        title: 'Protection history',
        access: ['user', 'account-owner', 'arxan-admin'],
        crumbs: ['Analyze']
    },
    keyAndData: {
        route: '/protect/on-premise/key-and-data',
        title: 'Key & Data',
        access: ['user', 'account-owner', 'arxan-admin'],
        crumbs: ['Protect']
    },
    releases: {
        route: '/protect/releases',
        title: 'Releases',
        access: ['arxan-admin'],
        crumbs: ['Protect']
    },
    appAwareDashboard: {
        route: '/analyze/app-aware/dashboard',
        title: 'Dashboard',
        access: ['user', 'account-owner', 'arxan-admin'],
        crumbs: ['Analyze', 'App Aware']
    },
    appAwareAlerts: {
        route: '/analyze/app-aware/alerts',
        title: 'Alerts',
        access: ['user', 'account-owner', 'arxan-admin'],
        crumbs: ['Analyze', 'App Aware']
    },
    appAwareEvents: {
        route: '/analyze/app-aware/events',
        title: 'Events',
        access: ['user', 'account-owner', 'arxan-admin'],
        crumbs: ['Analyze', 'App Aware']
    },
    supportAppAwareSetup: {
        route: '/manage/support/app-aware',
        title: 'App Aware',
        access: ['arxan-admin'],
        crumbs: ['Manage']
    },
    manageAppAwareSetup: {
        route: '/manage/setup/app-aware',
        title: 'App Aware',
        access: ['user', 'account-owner'],
        crumbs: ['Manage']
    },
    manageFileShare: {
        route: '/manage/support/file-share',
        title: 'File share',
        access: ['arxan-admin', 'user', 'account-owner'],
        crumbs: ['Manage']
    },
    manageApiCredentials: {
        route: '/manage/setup/api-credentials',
        title: 'API credentials',
        access: ['user', 'account-owner'],
        crumbs: ['Manage']
    },
    manageTeam: {
        route: '/manage/system/team',
        title: 'Team',
        access: ['account-owner'],
        crumbs: ['Manage']
    },
    manageUsers: {
        route: '/manage/system/users',
        title: 'Users',
        access: ['arxan-admin'],
        crumbs: ['Manage']
    },
    manageCompanies: {
        route: '/manage/system/companies',
        title: 'Companies',
        access: ['arxan-admin'],
        crumbs: ['Manage']
    },
    manageContracts: {
        route: '/manage/system/contracts',
        title: 'Contracts',
        access: ['arxan-admin'],
        crumbs: ['Manage']
    },
    manageAllowedCountries: {
        route: '/manage/system/allowed-countries',
        title: 'Allowed countries',
        access: ['arxan-admin'],
        crumbs: ['Manage']
    },
    manageMaliciousPackageOverrides: {
        route: '/manage/system/malicious-packages/overrides',
        title: 'Malicious packages',
        access: ['arxan-admin'],
        crumbs: ['Manage']
    },
    manageMaliciousPackages: {
        route: '/manage/system/malicious-packages',
        title: 'Malicious packages',
        access: ['arxan-admin', 'security-researcher'],
        crumbs: ['Manage']
    },
    manageMaliciousPackageExclusions: {
        route: '/manage/system/malicious-package-exclusions',
        title: 'Malicious package exclusions',
        access: ['user', 'account-owner'],
        crumbs: ['Manage']
    },
    manageNotifications: {
        route: '/manage/support/notifications',
        title: 'Notifications',
        access: ['arxan-admin'],
        crumbs: ['Manage']
    },
    manageNotificationsCreate: {
        route: '/manage/support/notifications/create',
        title: 'Create',
        access: ['arxan-admin'],
        crumbs: ['Manage']
    },
    manageNotificationCreateById: {
        route: '/manage/support/notifications/create/:messageId',
        title: 'Create',
        access: ['arxan-admin'],
        crumbs: [
            'Manage',
            {
                text: 'Notifications',
                underline: 'always',
                ariaLabel: 'Notifications',
                routeName: 'manageNotifications'
            }
        ],
        regex: /^\/manage\/support\/notifications\/create\/[^/]+$/
    },
    notifications: {
        route: '/notifications',
        title: 'Notifications',
        access: ['user', 'account-owner'],
        crumbs: []
    },
    notificationById: {
        route: '/notifications/:notificationId',
        title: 'Notifications',
        access: ['user', 'account-owner'],
        crumbs: [],
        regex: /^\/notifications\/[^/]+$/
    },
    responseRules: {
        route: '/internal/response-rules',
        title: '',
        access: ['account-owner', 'arxan-admin'],
        crumbs: []
    },
    login: {
        route: '/login',
        title: 'Login',
        access: [],
        crumbs: []
    },
    ssoLogin: {
        route: '/sso-login',
        title: 'Digital.ai',
        access: [],
        crumbs: []
    },
    logout: {
        route: '/logout',
        title: 'Logout',
        access: ['user', 'account-owner', 'arxan-admin', 'otp', 'security-researcher'],
        crumbs: []
    },
    profile: {
        route: '/profile',
        title: 'Profile',
        access: ['user', 'account-owner', 'arxan-admin', 'security-researcher'],
        crumbs: []
    },
    setup: {
        route: '/setup',
        title: 'Setup',
        access: [],
        crumbs: []
    },
    home: {
        route: '',
        title: 'Home',
        access: [],
        crumbs: []
    },
    zendesk: {
        route: 'https://digitalai.zendesk.com/',
        title: '',
        access: [],
        crumbs: []
    },
    community: {
        route: 'https://community.digital.ai/',
        title: '',
        access: [],
        crumbs: []
    },
    documentation: {
        route: 'https://docs.digital.ai/',
        title: '',
        access: [],
        crumbs: []
    }
};

export const TITLES = {
    SUFFIX: ' - Digital.ai Application Security Portal',
    DEFAULT: 'Digital.ai Application Security Portal',
    NOT_FOUND: 'Page Not Found'
};

export const BREADCRUMB_ROOT = 'Home';

export const NOTIFICATION_TYPES = {
    criticalAnnouncements: {
        key: 'criticalAnnouncements', label: 'Critical Announcements', severity: 'warning', iconId: 'error-solid', color: 'warning'
    },
    platformSupport: {
        key: 'platformSupport', label: 'Platform Support', severity: 'success', iconId: 'add-solid', color: 'success'
    },
    generalAnnouncements: {
        key: 'generalAnnouncements', label: 'General Announcements', severity: 'info', iconId: 'info-solid', color: 'primary'
    }
};

export const LICENSES_KEYS = {
    offline: 'offline',
    nonNodeLocked: 'nonnodelocked',
    floating: 'floating',
    nodeLock: 'nodelock'
};

export const LICENSE_NAME_MAX_LENGTH = 30;

export const GUARD_TYPES = {
    debugDetection: 2,
    webAppIntegrity: 24
};

// TECH_DEBT: S-106369
// Leaving this here for now as adding the reference to the constants package may cause conflicts
export const ACTIVITY_TYPES = {
    nonTamper: 0,
    tamper: 1,
    unknown: 2,
    updatableGuard: 3,
    dynamicGuardRequest: 4
};

const global = {
    ROLES,
    ROUTES,

    // strings
    DROPDOWN_SELECTED: ' selected',
    PASSWORD_POLICY: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,

    SETUP_PAGE_ROUTE: 'setup',

    INVITE_ROLES: {
        'arxan-admin': ['arxan-admin', 'security-researcher'],
        'account-owner': ['user']
    },

    ROLE_TITLES: {
        user: 'User',
        'account-owner': 'Account Administrator',
        'arxan-admin': 'Administrator',
        'security-researcher': 'Security Researcher'
    },

    ROLES_IN_A_TEAM: ['user', 'account-owner'],

    USER_STATUS: {
        active: 'Active',
        inactive: 'Inactive',
        invited: 'Invitation pending',
        passwordReset: 'Password setup pending'
    },

    // Release Statuses for he Release List Page (based on isPublished field)
    RELEASE_ELECTABLE_STATUS: {
        published: 'Published',
        unpublished: 'Unpublished'
    },

    NON_EDITABLE_ROLES: [ROLES.admin, ROLES.accountOwner, ROLES.securityResearcher],

    STORAGE: {
        keys: {
            user: 'arxan-user-data',
            preferredLanguage: 'arxan-user-preferred-language',
            reportsApp: 'reports-selected-app',
            reportsCompany: 'reports-selected-company'
        }
    },

    RELEASE_VERSION_REGEX: /^((\d+\.)+(\d+[\w\d\s_()]*)$)|^([\w\d\s_()])*$/,

    // upload types for Releases
    RELEASE_UPLOAD_TYPES: {
        all: 'All platforms',
        windows: 'Windows',
        mac: 'Mac OS X',
        linux: 'Linux',
        docs: 'Supporting files'
    },

    RELEASE_UPLOAD_TYPES_DROPDOWN: ['all', 'windows', 'mac', 'linux'],

    UPLOAD_EXTENSION_ICONS: {
        pdf: 'file-pdf-o',
        gif: 'file-image-o',
        jpg: 'file-image-o',
        png: 'file-image-o',
        jpeg: 'file-image-o',
        svg: 'file-image-o',
        doc: 'file-word-o',
        docx: 'file-word-o',
        xls: 'file-excel-o',
        xslx: 'file-excel-o',
        ppt: 'file-powerpoint-o',
        pptx: 'file-powerpoint-o',
        zip: 'file-archive-o',
        rar: 'file-archive-o',
        tar: 'file-archive-o',
        gz: 'file-archive-o',
        gzip: 'file-archive-o',
        py: 'python',
        js: 'js-square',
        txt: 'file-text-o'
    },

    UPLOAD_EXTENSION_ICON_DEFAULT: 'file-o',

    FILETYPE_TO_EXTENSION: {
        python: 'py',
        javascript: 'js'
    },

    // ADP upload statuses same as on backend (db field) for all uploads except APaaS
    ADP_UPLOAD_STATUSES: {
        error: 'error',
        uploaded: 'finished',
        uploading: 'in progress',
        started: 'started'
    },

    TRANSFORMIT_STATUS_READY: 'Ready',
    TRANSFORMIT_STATUS_IN_PROGRESS: 'In progress',

    LM_URL: 'http://54.93.93.7',
    LM_GATHER_MIN_VERSION: '2.6',
    LM_ID_PLATFORM_FOR_ENSUREIT: 17,

    // TODO - Align the way we get the product name when the product has flavours for all pages/products
    GUARD_IT_JAVA_PRODUCT_NAME: 'GuardIT Java',

    KIT_REQUEST_FORM_FIELDS: {
        targetPlatform: ['Android ARM', 'Android Intel', 'iOS', 'Linux ARM', 'Linux MIPS', 'Linux x86', 'Mac OSX', 'Windows'],
        cipher: ['ECC', 'AES', '3DES', 'DES', 'RSA']
    },

    TRANSFORMIT_UNIQUE_KEY: 'TransformIT__c',

    TIMEOUT: 500000,

    MIN_PASSWORD_LENGTH: 8,
    MAX_PASSWORD_LENGTH: 30,
    MAX_KIT_MESSAGE_LENGTH: 500,
    DEFAULT_INPUT_MAX_LENGTH: 30,

    // interval between estimated time measurment
    UPLOAD_ESTIMATED_TIME_CHECK_INTERVAL: 4000,
    // average time will be computed from X last time values (moving avg)
    UPLOAD_ESTIMATED_TIME_GRANULARITY: 5,
    // first time appears after Xth measurment
    UPLOAD_ESTIMATED_TIME_MIN_GRANULARITY: 5,

    LANGUAGE_MAP: {
        en: 'us'
    },

    RELEASE_TYPES: {
        PI: 'PI',
        GA: 'GA'
    },

    // how many company names should be shown on form field
    PI_TARGET_ITEMS_TO_SHOW: 3,

    CONTRACT_LICENSE_COUNT: 200,
    CONTRACT_MINIMUM_CHECKOUT: 7200,

    UPLOAD_PARSING_TYPES: {
        CONTENT: 'content'
    },

    UPLOAD_TYPES: {
        APAAS: 'apaas',
        RELEASE: 'release',
        TFIT: 'tfit',
        SHARE: 'share',
        LICENSE: 'license',
        FINGERPRINT: 'fingerprint',
        CLASS_LIST: 'class_list'
    },

    UPLOAD_STATUSES: {
        INIT: 'initialized',
        START: 'started',
        PROGRESS: 'onprogress',
        ERROR: 'error',
        COMPLETE: 'complete',
        ABORT: 'abort',
        PAUSE: 'pause',
        RESUME: 'resume'
    },

    UPLOAD_ERRORS: {
        ERROR_NOT_ALLOWED_EXTENSIONS: 'notAllowedExtensions',
        ERROR_FILE_TOO_SMALL: 'fileTooSmall',
        ERROR_FILE_TOO_BIG: 'fileTooBig',
        ERROR_FILE_EXISTS: 'fileExists',
        ERROR_FILE_IS_UPLOADING_AUTOMATICALLY: 'fileUploadingAutomatically',
        ERROR_MAX_FILES_EXCEEDED: 'maxFilesError',
        ERROR_FILE_CONTENT_IS_NOT_A_STRING: 'fileContentIsNotAString',
        ERROR_NO_INIT_DATA_FROM_SERVER: 'noInitData'
    },

    MAX_FILE_UPLOAD_SIZE_BYTES: 5368709120,
    MAX_FILE_UPLOAD_SIZE_MB: 5368709120 / 1048576,
    MIN_FILE_UPLOAD_SIZE: 1,

    TILE_COLORS: [['#4da7ff', '#0081ff'], ['#70dae4', '#01c2d3'], ['#5ce575', '#3dcc57'], ['#ee7f92', '#e84c67'], ['#b397d3', '#7d4db6']],

    LICENSE_STATUSES: {
        ready: 'Ready',
        expired: 'Expired',
        progress: 'In progress',
        pending: 'Pending',
        error: 'Failed',
        downloaded: 'Downloaded',
        issued: 'Issued'
    },

    SOURCES: {
        GUI: {
            key: 'GUI',
            label: 'Cloud'
        },
        API: {
            key: 'API',
            label: 'API'
        }
    },

    UKNOWN_GUARD_CATEGORY: 'Unknown',

    NOTIFICATIONS: {
        TIMEOUT: 10,
        BANNER_CLOSED: 'bannerClosed'
    },

    EXTERNAL_NOTIFICATION_TYPES: {
        email: {id: 'email', title: 'Email'},
        webhook: {id: 'webhook', title: 'Webhook'}
    },

    SUCCESS: 'success',
    ERROR: 'error',
    PRIVACY_LINK: 'https://digital.ai/privacy-policy',

    DATE_PRESET_SELECTOR: {
        MONTH: 'MONTH',
        WEEK: 'WEEK',
        TWO_DAYS: 'TWO_DAYS',
        TODAY: 'TODAY'
    },

    OPEN_IN_BROWSER_EXTENSIONS: [
        'pdf',
        'gif',
        'jpg',
        'png',
        'jpeg',
        'svg'
    ],

    UNCATEGORIZED_FILE_TYPE: 'uncategorized',

    APP_AWARE_FEATURE_ID: 2,
    APP_AWARE_PREV_WEEKS_TO_SHOW: 4,
    APP_AWARE_PREV_MONTHS_TO_SHOW: 2,

    // START - CUSTOM CONFIG FOR EACH ENVIRONMENT.
    ADP_MOCK_DATA_ENABLED,
    ADP_API_URL,
    ADP_FRONTEND_URL,
    ADP_VANITY_DOMAIN,
    GOOGLE_ANALYTICS_ENABLED,
    GA_TRACKING_ID,
    HIDDEN_ROUTES,
    K6I_SIGNIN_PAGE_URL,
    APAAS_DISABLED
    // END - CUSTOM CONFIG FOR EACH ENVIRONMENT.
};

global.OS = (() => {
    let os;
    Object.keys(global.RELEASE_UPLOAD_TYPES).forEach((key) => {
        if (navigator.appVersion.toLowerCase().indexOf(key) !== -1) os = key;
    });
    return os;
})();

export default global;
